export const REQUEST_CASH_BACK_WITHDRAWAL = `
mutation RequestCashbackWithdrawal {
    me {
        requestCashbackWithdrawal {
                id,
                status,
                value,
                registeredAt,
                previousStatus {
                    status,
                    registeredAt
                }
        }
    }
}
`;
