import { EmitTrackingEvent } from '../../domain/events/analytics';
import { publishDomainEvent } from '../events';

export const emitTrackingEvent: EmitTrackingEvent = ({
  category,
  name,
  extra,
}) => {
  publishDomainEvent('analytics', { category, name, extra });
};
