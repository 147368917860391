import router from 'next/router';
import { ReactNode, useEffect } from 'react';
import initAnalyticsSubscribe from '../../infra/analytics/trackEventCapture';
import { getPelandoEvents$ } from '../../infra/streams';
import { config } from '../../infra/analytics/ga';
import {
  getAllFlagsOnClientSide,
  handlePageView,
} from '../../infra/analytics/sendEventToTrackerProxy';

type RouteOnHandler = Parameters<typeof router.events.on>[1];

export default function EventProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    config();

    const subscription = initAnalyticsSubscribe(getPelandoEvents$());

    const isPopEvent = (url: string): boolean => window.pathsStack?.[1] === url;

    const handleRouteChange: RouteOnHandler = (url) => {
      if (isPopEvent(url)) {
        window.pathsStack?.shift();
        return;
      }

      const flags = getAllFlagsOnClientSide();
      handlePageView(router.asPath, { flags });

      if (!window.pathsStack) window.pathsStack = [router.asPath];
      window.pathsStack.unshift(router.asPath);
    };

    if (!window.pathsStack) window.pathsStack = [router.asPath];
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      subscription.unsubscribe();
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
