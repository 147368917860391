import {
  Icon,
  Image,
  MediaQuery,
  TextInputMessage,
  TextInputMessageStatus,
  useModal,
} from '@pelando/components';
import { CircleInfo, PenToSquare } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LockScrollGlobally } from '@/presentation/styles/global';
import Translation from '@/presentation/components/Translation';
import useMediaQuery from '@/presentation/hooks/mediaQuery';
import { updateBankAccountDetails } from '@/infra/api/cashback/endpoints';
import { PixKeyType } from '@/domain/entities/Cashback';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import {
  ConfirmButton,
  Container,
  FormContent,
  FormLabel,
  FormTextInput,
  Subtitle,
  Tip,
  Title,
  Header,
} from './style';
import { formatCPF, isValidCPF, isValidForm } from './validation';
import CashbackSuccessErrorModal, {
  ModalType,
} from '../CashbackSuccessErrorModal';

export interface FormState {
  name: string;
  cpf: string;
  pixKey: string;
}

const CashbackForm = () => {
  const { t } = useTranslation('cashbackBalance');
  const { showModal, closeModal } = useModal();

  const inputNameRef = useRef<HTMLInputElement>(null);
  const inputCpfRef = useRef<HTMLInputElement>(null);
  const inputPixkeyRef = useRef<HTMLInputElement>(null);

  const handleIconNameClick = () => {
    inputNameRef?.current?.focus();
  };

  const handleIconCpfClick = () => {
    inputCpfRef?.current?.focus();
  };

  const handleIconPixkeyClick = () => {
    inputPixkeyRef?.current?.focus();
  };

  const initialFormState = {
    name: '',
    cpf: '',
    pixKey: '',
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [validate, setValidate] = useState<boolean>(false);
  const { loading } = useApiCache(updateBankAccountDetails.cache$) || {};
  const [showCpfError, setShowCpfError] = useState<boolean>(false);
  const [cpfErrorMessage, setCpfErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    const valid = isValidForm(formState);

    setValidate(valid);
  }, [formState]);

  const handleChangeName = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setFormState((prev) => ({ ...prev, name: value }));
  };

  const handleChangeCPF = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const formattedCPF = formatCPF(value);
    setFormState((prev) => ({ ...prev, cpf: formattedCPF }));
    setShowCpfError(formattedCPF.length === 14 && !isValidCPF(formattedCPF));
  };

  const handleChangePixkey = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setFormState((prev) => ({ ...prev, pixKey: value }));
  };

  const handleSubmitForm = useCallback(async () => {
    const { cpf, name: fullName, pixKey: key } = formState;

    const cpfWithoutCharacters = cpf.replace(/[.-]/g, '');

    try {
      const { loading } = await updateBankAccountDetails.requestAsPromise({
        bankAccountDetails: {
          cpf: cpfWithoutCharacters,
          fullName,
          pix: { type: PixKeyType.RANDOM, key },
        },
      });

      if (!loading) {
        showModal(
          <>
            <CashbackSuccessErrorModal
              title={t('cashback-success-modal-title')}
              subtitle={t('cashback-success-modal-subtitle')}
              type={ModalType.SUCCESS}
              onCloseModal={closeModal}
            />
            <LockScrollGlobally lock />
          </>
        );
      }
    } catch (error) {
      const { message } = error as { message: string };
      setCpfErrorMessage(message);
      setShowCpfError(true);
    }
  }, [formState, showModal, closeModal, t]);

  const messageCpfError: TextInputMessage = {
    type: TextInputMessageStatus.ERROR,
    text: cpfErrorMessage || t('cashback-form-cpf-error'),
  };

  return (
    <Container>
      <Header>
        <Image
          width={32}
          height={32}
          alt=""
          src="/assets/cashbackBalance/dollar-return-icon.png"
          fetchPriority="high"
        />
        <Title>{t('cashback-form-modal-title')}</Title>
        <Subtitle>{t('cashback-form-modal-subtitle')}</Subtitle>
      </Header>
      <FormContent>
        <FormLabel>{t('cashback-form-label-name')}</FormLabel>
        <FormTextInput
          innerRef={inputNameRef}
          placeholder="Nome completo"
          value={formState.name}
          icon={PenToSquare}
          onChange={handleChangeName}
          onIconClick={handleIconNameClick}
        />
        <FormLabel>{t('cashback-form-label-cpf')}</FormLabel>
        <FormTextInput
          innerRef={inputCpfRef}
          placeholder="000.000.000-00"
          value={formState.cpf}
          icon={PenToSquare}
          onChange={handleChangeCPF}
          onIconClick={handleIconCpfClick}
          message={showCpfError ? messageCpfError : undefined}
        />

        <FormLabel>{t('cashback-form-label-pixkey')}</FormLabel>
        <FormTextInput
          innerRef={inputPixkeyRef}
          placeholder="Chave Pix"
          value={formState.pixKey}
          icon={PenToSquare}
          onChange={handleChangePixkey}
          onIconClick={handleIconPixkeyClick}
        />
      </FormContent>
      <Tip>
        <Icon icon={CircleInfo} />
        <span>
          <Translation translation={t('cashback-form-tip')} hasTag>
            O titular da conta bancária <strong> deve ser o mesmo </strong>da
            sua conta no Pelando.
          </Translation>
          {}
        </span>
      </Tip>
      <ConfirmButton
        onClick={handleSubmitForm}
        disabled={!validate}
        loading={loading}
      >
        {t('cashback-form-button')}
      </ConfirmButton>
    </Container>
  );
};

export const useCashbackFormModal = () => {
  const { showModal, closeModal: closeFormModal } = useModal();
  const { t } = useTranslation('cashbackBalance');
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);

  const showFormModal = useCallback(() => {
    showModal(
      <>
        <CashbackForm />
        <LockScrollGlobally lock />
      </>,
      {
        modalPageStyle: isMobile,
        title: isMobile ? t('cashback-form-title') : undefined,
      }
    );
  }, [showModal, isMobile, t]);

  return {
    showFormModal,
    closeFormModal,
  };
};

export default CashbackForm;
